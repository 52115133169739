import DPA, { connect } from 'servisofts-page';
import { Parent } from '.';
import { SNavigation, SPopup } from 'servisofts-component';
import Model from '../../Model';

class index extends DPA.edit {
    constructor(props) {
        super(props, {
            Parent: Parent,
            title: "Editar Cupón",
            excludes: ["lat", "lng", "fecha_on", "tipo"]
        });
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" })
    }
    $getData() {
        return Parent.model.Action.getByKey(this.pk);
    }
    $inputs() {
        var inp = super.$inputs();
        inp["descripcion"].label = "Código del Cupón"

        inp["observacion"].label = "Descripción del Cupón"

        inp["monto"].type = "money"
        inp["monto"].defaultValue = parseFloat(inp["monto"].defaultValue ?? 0).toFixed(2);

        inp["monto_minimo_compra"].type = "money"
        inp["monto_minimo_compra"].label = "Monto mínimo de compra"
        inp["monto_minimo_compra"].defaultValue = parseFloat(inp["monto_minimo_compra"].defaultValue ?? 0).toFixed(2);

        inp["fecha_off"].label = "Fecha de expiración"
        inp["fecha_off"].type = "date"
        inp["fecha_off"].defaultValue = new Date(inp["fecha_off"].defaultValue ?? 0).toISOString().split("T")[0];

        inp["cantidad_maxima_usuarios"].label = "Cantidad máxima de usuarios"
        inp["cantidad_maxima_usuarios"].type = "number"
        inp["cantidad_maxima_usuarios"].defaultValue = inp["cantidad_maxima_usuarios"].defaultValue+"";

        return inp;
    }
    $onSubmit(data) {
        if (parseFloat(data.monto_minimo_compra) <= parseFloat(data.monto)) {
            SPopup.alert("El valor del cupón debe ser menor al monto mínimo de compra.")
            return;
        }
        Parent.model.Action.editar({
            data: {
                ...this.data,
                ...data
            },
            key_usuario: Model.usuario.Action.getKey(),
        }).then((resp) => {
            SNavigation.goBack();
        }).catch(e => {
            console.error(e);

        })
    }
}

export default connect(index);