import { SPage, SPageListProps } from 'servisofts-component';

import Root from './root';
import login from './login';
import profile from './profile';
import usuario from './usuario';
import empresa from './empresa';
import rol from './rol';
import cliente from './cliente';
import sucursal from './sucursal';
import ajustes from './ajustes';
import marketing from './marketing';
import restaurante from './restaurante';
import restaurante_cuenta from './restaurante_cuenta';
import zona from './zona'
import billetera from './billetera';
import test from './test';
import test2 from "./test2";
import reporte from './reporte';
import conciliacion from './conciliacion';
import chat from './chat';
import cupon from './cupon';
import pedido from './pedido';
import incentivo from './incentivo';
import base_notification from "./_trash/base_notification";
import driver from './driver'
import mapa from './mapa';
import sms from './sms';
 import sql from './sql';
 import descuento from './descuento';
 import devtool from './devtool';
 import notification from './notification';
 import company from './company';
import gpx from './gpx';

export default SPage.combinePages("/", {
    "": Root,
    "login": login,
    "test": test,
    test2,
    "sql":sql,
    ...mapa,
    ...driver,
    ...pedido,
    ...profile,
    ...usuario,
    ...empresa,
    ...rol,
    ...sucursal,
    ...ajustes,
    ...cliente,
    ...marketing,
    ...restaurante,
    ...restaurante_cuenta,
    ...zona,
    ...billetera,
    ...reporte,
    ...conciliacion,
    ...chat,
    ...cupon,
    ...incentivo,
    ...base_notification,
    ...sms,
    ...descuento,
    ...devtool,
    ...notification,
    ...company,
    ...gpx,
});