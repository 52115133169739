import DPA, { connect } from 'servisofts-page';
import { Parent } from '.';
import { SNavigation, SPopup } from 'servisofts-component';
import Model from '../../Model';

class index extends DPA.edit {
    constructor(props) {
        super(props, {
            Parent: Parent,
            title: "Editar Descuento",
            excludes: ["key", "fecha_on", "key_usuario", "estado", "delivery", "producto"]
        });
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" })
    }
    $getData() {
        return Parent.model.Action.getByKey(this.pk);
    }
    $inputs() {
        var inp = super.$inputs();

        inp["fecha_inicio"].label = "Fecha de Inicio"
        inp["fecha_inicio"].type = "date"
        inp["fecha_inicio"].defaultValue = new Date(inp["fecha_inicio"].defaultValue ?? 0).toISOString().split("T")[0];

        inp["fecha_fin"].label = "Fecha de expiración"
        inp["fecha_fin"].type = "date"
        inp["fecha_fin"].defaultValue = new Date(inp["fecha_fin"].defaultValue ?? 0).toISOString().split("T")[0];

        inp["monto"].type = "money"
        inp["monto"].defaultValue = parseFloat(inp["monto"].defaultValue ?? 0).toFixed(2);

        inp["porcentaje"].type = "number"
        inp["porcentaje"].defaultValue = parseFloat(inp["porcentaje"].defaultValue ?? 0) * 100; // TODO No me pone en el input el 0.

        inp["descripcion"].defaultValue = inp["descripcion"].defaultValue;

        inp["isglobal"].type = "select"
        inp["isglobal"].options = [{ key: "", content: "SELECCIONAR" }, { key: "true", content: "SI" }, { key: "false", content: "NO" }]
        inp["isglobal"].defaultValue = inp["isglobal"].defaultValue + ""

        inp["cobertura"].type = "number"
        inp["cobertura"].defaultValue = parseFloat(inp["cobertura"].defaultValue ?? 0) * 100; // TODO No me pone en el input el 0.
        return inp;
    }
    $onSubmit(data) {
        let procentaje = parseFloat(data.porcentaje);
        let cobertura = parseFloat(data.cobertura);

        data.fecha_fin = data.fecha_fin + "T23:59:59.999Z";

        if (procentaje >= 0 && procentaje <= 100) {
            data.porcentaje = procentaje / 100;
        } else {
            SPopup.alert("El porcentaje del descuento tiene que estar entre 0 y 100")
            return;
        }

        if (cobertura >= 0 && cobertura <= 100) {
            data.cobertura = cobertura / 100;
        } else {
            SPopup.alert("El porcentaje de cobertura Tapeke tiene que estar entre 0 y 100")
            return;
        }

        Parent.model.Action.editar({
            data: {
                ...this.data,
                ...data
            },
            key_usuario: Model.usuario.Action.getKey(),
        }).then((resp) => {
            SNavigation.goBack();
        }).catch(e => {
            console.error(e);

        })
    }
}

export default connect(index);