import DPA, { connect } from 'servisofts-page';
import { Parent } from ".."
import { SHr, SNotification, SPopup, SText, STheme, SView, SNavigation } from 'servisofts-component';
import Model from '../../../Model';
import SSocket from 'servisofts-socket';

class index extends DPA.profile {
    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: ["key", "key_usuario", "estado", "latitude", "longitude"],
            // item: item
        });

        this.params = SNavigation.getAllParams() ?? {};
    }
    $allowBack() {
        return true;
    }

    $allowEdit() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" })
    }

    $allowDelete() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "delete" })
    }

    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" })
    }

    $getData() {
        return Parent.model.Action.getByKey(this.pk);
    }

    $footer() {

        return <>
            <SHr />
            <SView col={"xs-12"}
                center
                style={{
                    backgroundColor: STheme.color.danger,
                    borderRadius: 10,
                    height: 100,
                    width: 100
                }}
                onPress={() => {
                    SPopup.confirm({
                        title: "¿Seguro que desea duplicar este restaurante?",
                        onPress: () => {
                            SNotification.send({
                                key: "duplicar_restaurante",
                                title: "Actualizando",
                                body: "Estamos guardando los cambios.",
                                type: "loading"
                            })

                            SSocket.sendPromise({
                                component: "restaurante",
                                type: "clonar",
                                key_restaurante: this.params.pk,
                                key_usuario: Model.usuario.Action.getKey()
                            }).then(async (e) => {
                                SNotification.remove("duplicar_restaurante")
                                SNotification.send({
                                    title: "Acción",
                                    body: "Restaurante Duplicado con exito",
                                    time: 2000,
                                    color: STheme.color.success,
                                })

                                SNotification.send({
                                    key: "navegar_restaurante",
                                    title: "Navegando",
                                    body: "Estamos navegando al restaurante",
                                    time: 5000,
                                })
                                SNavigation.navigate("/restaurante/profile", { pk: e?.data?.key })
                            }).catch(err => {
                                SNotification.remove("duplicar_restaurante")
                                SNotification.send({
                                    title: "Acción",
                                    body: `Ocurrio un error al guardar - ${err.data}. `,
                                    time: 5000,
                                    color: STheme.color.danger,
                                })
                                console.log(err.data);
                            })
                        }
                    })
                }}
            >
                <SText center>Duplicar Restaurante</SText>
            </SView>
        </>
    }

}
export default connect(index);