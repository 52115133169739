import DPA, { connect } from 'servisofts-page';
import { SList, SLoad, SNavigation, SIcon, SText, SView, SPopup, SMath, SImage, SSwitch, SButtom, SHr, STheme, SForm, SInput, SDate } from 'servisofts-component';
import MenuOption from '../../../../Components/MenuOption';

import SSocket from 'servisofts-socket'

import Model from '../../../../Model'
import { Switch } from 'react-native';

const Parent = {
    name: "producto",
    path: "/restaurante/producto",
    model: Model.producto
}
class index extends DPA.list {
    constructor(props) {
        super(props, {
            Parent: Parent,
            type: "componentTitle",
            excludes: ["fecha_on", "key_usuario", "estado"],
        });

        if (this.props.onSelect) {
            this.onSelect = this.props.onSelect;
        }
    }

    componentDidMount() {
        Parent.model.Action.CLEAR()
        Model.categoria_producto.Action.CLEAR()
    }

    $allowNew() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" });
    }

    onNew() {
        SNavigation.navigate(Parent.path + "/new", { key_restaurante: this.props.key_restaurante })
    }

    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" });
    }

    $filter(data) {
        return data.estado != 0
    }

    $getData() {
        return Parent.model.Action.getAll({ key_restaurante: this.props.key_restaurante });
    }

    $getDataCategoriaProducto() {
        return Model.categoria_producto.Action.getAll({ key_restaurante: this.props.key_restaurante });
    }

    navListCategoria() {
        return <>
            <SButtom onPress={() => {
                SNavigation.navigate("/restaurante/categoria_producto", { key_restaurante: this.props.key_restaurante })
            }}>Lista Categorías</SButtom>
        </>
    }

    $menu() {
        let menu = super.$menu();
        menu.push({ children: this.navListCategoria() })
        return menu;
    }

    onEdit(obj) {
        SNavigation.navigate(Parent.path + "/edit", { pk: obj?.key, key_restaurante: this.props.key_restaurante })
    }

    handleChange_habilitado(obj) {
        if (!this.editPermiso) {
            SPopup.alert("No tienes permisos para esta acción.")
            return;
        }

        Model.producto.Action.editar({
            data: {
                ...obj,
                habilitado: !obj.habilitado
            },
            key_usuario: Model.usuario.Action.getKey()
        })
    }

    handleChange_descuento(data, val) {
        if (!this.editPermiso) {
            SPopup.alert("¿No tienes permisos para esta acción.")
            return;
        }

        if (val) {
            SPopup.openContainer({
                render: (props) => {
                    return <SView col={"xs-11"} center >
                        <SHr h={30} />
                        <SText bold>Crear un descuento de producto</SText>
                        <SHr />
                        <SForm inputs={{
                            descuento_monto: { label: "Descuento Monto", type: "number" },
                            descuento_porcentaje: { label: "Descuento Porcentaje", type: "number" }
                        }}
                            onSubmitName={"ACEPTAR"}
                            onSubmit={(val) => {
                                props.close();
                                this.setState({ loading: true })

                                if (val.descuento_porcentaje) {
                                    if (val.descuento_porcentaje <= 100) {
                                        val.descuento_porcentaje = val.descuento_porcentaje / 100;
                                    } else {
                                        this.setState({ loading: false })
                                        SPopup.alert("El porcentaje del descuento tiene que estar entre 0 y 100")
                                        return;
                                    }
                                }

                                if (val.descuento_monto) {
                                    if (parseFloat(val.descuento_monto) > data.precio) {
                                        this.setState({ loading: false })
                                        SPopup.alert("El descuento monto no puede ser mayor al precio del producto.")
                                        return;
                                    }
                                }


                                Model.producto.Action.editar({
                                    data: {
                                        ...data,
                                        descuento_monto: val.descuento_monto,
                                        descuento_porcentaje: val.descuento_porcentaje,
                                    },
                                    key_usuario: Model.usuario.Action.getKey()
                                }).then(e => {
                                    this.setState({ loading: false })
                                }).catch(e => {
                                    this.setState({ loading: false })
                                })
                            }}
                        />
                        <SHr h={30} />
                    </SView>
                }
            })

        } else {
            title: "¿Esta seguro de desactivar el descuento producto?",
                SPopup.confirm({
                    onPress: () => {
                        this.setState({ loading: true })
                        Model.producto.Action.editar({
                            data: {
                                ...data,
                                descuento_monto: 0,
                                descuento_porcentaje: 0,
                            },
                            key_usuario: Model.usuario.Action.getKey()
                        }).then(e => {
                            this.setState({ loading: false })
                        }).catch(e => {
                            this.setState({ loading: false })
                        })
                    }
                })
        }
    }

    onDelete(obj) {
        if (this.deletePermiso) {
            SPopup.confirm({
                title: "Eliminar Producto",
                message: "¿Seguro que desea remover el producto?",
                onPress: () => {
                    Model.producto.Action.editar({
                        data: {
                            // ...obj,  
                            key: obj?.key,
                            estado: 0
                        },
                        key_usuario: Model.usuario.Action.getKey()
                    })
                }
            })
        } else {
            SPopup.alert("No tiene permisos para eliminar el tag")
        }
    }


    viewProductoCategoria(keyCategoria, dataProducto) {

        // if (!dataProducto) return null

        this.deletePermiso = Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "delete" });
        this.editPermiso = Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" });
        this.descuentoPermiso = Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "verDescuento" });

        return <>
            <SList
                data={dataProducto}
                order={[{ key: "index", order: "asc" }]}
                filter={(producto) => {
                    if (producto.estado == 0) {
                        return false;
                    }

                    if (producto.key_categoria_producto != keyCategoria) {
                        return false;
                    }

                    return true;
                }}
                render={(producto) => {
                    if (Object.values(producto).length <= 0) return null;
                    return <>
                        <SView flex card
                            style={{
                                padding: 15,
                                borderRadius: 10,
                            }}
                            onPress={this.props.onSelect ? () => this.props.onSelect(producto) : null}
                        >

                            <SView>
                                <SView flex row
                                    style={{
                                        justifyContent: "space-evenly",
                                        marginBottom: 15
                                    }}
                                >
                                    <SView height={80} width={80} style={{ marginRight: 10 }}>
                                        <SImage src={Model.producto._get_image_download_path(SSocket.api, producto?.key)} />
                                    </SView>

                                    <SView
                                        style={{
                                            marginLeft: 20
                                        }}
                                    >
                                        <SText margin={5} fontSize={12}>Index: {producto?.index}</SText>
                                        <SText margin={5} fontSize={12}>Nombre: {producto?.nombre}</SText>
                                        <SText margin={5} fontSize={12}>Descripción:</SText>
                                        <SView width={220}
                                            style={{
                                                maxHeight: 150,
                                                overflow: 'scroll'
                                            }}>
                                            <SText margin={5} fontSize={12}>{producto?.descripcion}</SText>
                                        </SView>
                                        <SText margin={5} fontSize={12}>Limite de compra: {producto?.limite_compra ? producto?.limite_compra : "No hay limite"}</SText>
                                        <SText margin={5} fontSize={12}>Precio: {SMath.formatMoney(producto?.precio) + " Bs."}</SText>
                                    </SView>
                                </SView>

                                <SHr height={40} />

                                <SView flex row
                                    style={{
                                        justifyContent: "space-evenly"
                                    }}
                                >
                                    <SView center style={{ marginRight: "10px" }}>
                                        <SView center>
                                            <SText>Mayor de Edad: {JSON.parse(producto?.mayor_edad) == true ? "SI" : "NO"}</SText>
                                            <SText>Ley Seca: {JSON.parse(producto?.ley_seca) == true ? "SI" : "NO"}</SText>
                                            <SHr h={5} />
                                            {this.componentHabilitado(producto)}
                                        </SView>
                                    </SView>
                                    <SView center>
                                        <SView row>
                                            <SView style={{ marginRight: "10px" }} onPress={() => this.onEdit(producto)}>
                                                {this.editPermiso ? <SIcon name={"Edit"} height={30} width={30}></SIcon> : <SView />}
                                            </SView>
                                            <SView onPress={() => this.onDelete(producto)}>
                                                {this.deletePermiso ? <SIcon name={"Delete"} height={30} width={30}></SIcon> : <SView />}
                                            </SView>
                                        </SView>
                                        <SHr height={20} />
                                        <SView
                                            style={{
                                                backgroundColor: STheme.color.primary,
                                                fontSize: 15,
                                                padding: 5,
                                                borderRadius: 4
                                            }}
                                            onPress={() => SNavigation.navigate(Parent.path + "/sub_producto/list", { key_producto: producto?.key })}>
                                            <SText>Ver sub productos</SText>
                                        </SView>
                                    </SView>
                                </SView>
                            </SView>

                            {this.descuentoPermiso
                                ? this.componetDescuento(producto)
                                : null
                            }
                        </SView>
                    </>
                }}
            />
        </>
    }

    tiempoHabilitacion(fechaHora) {
        const ahora = new Date();
        const fechaObjetivo = new Date(fechaHora);

        const diferencia = fechaObjetivo - ahora;
        if (diferencia < 0) {
            return "La fecha y hora ya han pasado";
        }

        const segundos = Math.floor(diferencia / 1000);
        const minutos = Math.floor(segundos / 60);
        const horas = Math.floor(minutos / 60);
        // const dias = Math.floor(horas / 24);

        if (horas == 0) {
            return `Faltan ${minutos % 60} minutos para la habilitación`;
        }

        return `Faltan ${horas % 24} horas, ${minutos % 60} minutos para la habilitación`;
    }

    componentHabilitado(producto) {
        let ph = {};

        if (producto?.fecha_habilitacion_automatica) {
            ph = { key: "", content: <SText color={STheme.color.danger}>{this.tiempoHabilitacion(producto?.fecha_habilitacion_automatica)}</SText> };
        } else {
            if (producto?.habilitado) {
                ph = { key: "true", content: <SText color={STheme.color.accent}>Habilitado</SText> };
            } else {
                ph = { key: "false", content: <SText color={STheme.color.danger}>deshabilitado</SText> };
            }
        }


        return <>
            <SView margin={2} row center>
                <SText fontSize={15}>Disponibilidad:</SText>
                <SHr width={10} />
                <SInput ref={ref => this.prodHabilitado = ref} value={ph} type={"select"} options={[
                    { key: "", content: "" },
                    { key: "true", content: <SText color={STheme.color.accent}>Habilitado</SText> },
                    { key: "false", content: <SText color={STheme.color.danger}>deshabilitado</SText> },
                    { key: "30", content: <SText color={STheme.color.danger}>No disponible por 30 Min</SText> },
                    { key: "60", content: <SText color={STheme.color.danger}>No disponible por 1 hora</SText> },
                    { key: "120", content: <SText color={STheme.color.danger}>No disponible por 2 hora</SText> },
                    { key: "180", content: <SText color={STheme.color.danger}>No disponible por 3 hora</SText> },
                    { key: "360", content: <SText color={STheme.color.danger}>No disponible por 6 hora</SText> },
                    { key: "720", content: <SText color={STheme.color.danger}>No disponible por 12 hora</SText> },
                    { key: "1440", content: <SText color={STheme.color.danger}>No disponible por 1  día</SText> },
                ]} onChangeText={(select) => {
                    Model.producto.Action.editar({
                        data: {
                            ...producto,
                            habilitado: select == "true",
                            fecha_habilitacion_automatica: (select != "true" && select != "false") ? new SDate().addMinute(parseInt(select)).toString("yyyy-MM-ddThh:mm:ss") : "null"
                        },
                        key_usuario: Model.usuario.Action.getKey()
                    })
                }} />
            </SView>
        </>
    }

    componetDescuento(producto) {
        let descuentoMonto = producto.descuento_monto || producto.descuento_monto > 0 ? true : false;
        let descuentoPorcentaje = producto.descuento_porcentaje || producto.descuento_porcentaje > 0 ? true : false;

        let descuentoBool = descuentoMonto || descuentoPorcentaje;

        const renderColorDescuento = () => {
            if (descuentoMonto || descuentoPorcentaje) {
                return STheme.color.success;
            }
            return STheme.color.danger;
        };

        let colorDescuento = renderColorDescuento();
        return <>
            <SView>
                <SHr h={2} color={colorDescuento} />
                <SText fontSize={20} color={colorDescuento} center>Descuentos</SText>
                <SHr h={2} color={colorDescuento} />
                <SHr height={20} />

                <SView row>
                    <SView>
                        <SText>Porcentaje: {producto?.descuento_porcentaje ? (producto?.descuento_porcentaje) * 100 + " %" : "No hay porcentaje"}</SText>
                        <SText>Monto: {producto?.descuento_monto ? producto?.descuento_monto : "No hay descuento monto"}</SText>
                    </SView>
                    <SView center flex>
                        <SSwitch key={producto?.key} size={30} loading={this.state.loading} onChange={this.handleChange_descuento.bind(this, producto)} value={descuentoBool}
                            style={{
                                justifyContent: "center",
                            }}
                        />
                    </SView>
                </SView>
            </SView>
        </>
    }

    $render() {
        this.dataProducto = this.$getData();
        this.dataCategoria = this.$getDataCategoriaProducto();

        if (!this.dataProducto && !this.dataCategoria) return <SLoad />

        return <SList
            // buscador
            data={this.dataCategoria}
            filter={this.$filter.bind(this)}
            order={[{ key: "index", order: "asc" }]}
            render={(categoria) => {
                if (Object.values(categoria).length <= 0) return null;

                return <>
                    <SView row>
                        <SText flex fontSize={20}>{categoria.nombre}</SText>
                        <SText fontSize={15} center>Index: {categoria.index}</SText>
                    </SView>
                    <SHr height={10} />
                    {this.viewProductoCategoria(categoria?.key, this.dataProducto)}
                </>

            }}
        />
    }
}   
export default connect(index);